<template>
  <div class="container">
    <h3>系统角色</h3>

    <el-table round :data="roles" size="mini" style="width: 100%" row-key="id" border :indent="24">
      <el-table-column prop="id" label="编号" width="45"></el-table-column>
      <el-table-column prop="name" label="名称" width="180"></el-table-column>
      <el-table-column prop="code" label="Code"></el-table-column>
      <el-table-column label="操作">
        <template slot="header">
          操作
          <el-button v-permission:add_role size="mini" @click="addRole()">添加</el-button>
        </template>
        <template slot-scope="scope">
          <el-button
            v-permission:modify_role
            size="mini"
            @click="modifyRole(scope.row)"
            type="primary"
          >
            修改
          </el-button>
          <el-button
            v-permission:delete_role
            v-if="!scope.row.children"
            size="mini"
            @click="removeRole(scope.row)"
            type="danger"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="提示"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="30%"
      destroy-on-close
    >
      <div>
        <el-form size="mini" :model="role" label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="role.name"></el-input>
          </el-form-item>

          <el-form-item label="Code">
            <el-input v-model="role.code"></el-input>
          </el-form-item>

          <el-form-item label="菜单">
            <div class="tree">
              <el-tree
                ref="tree"
                default-expand-all
                v-if="dialogVisible"
                :default-checked-keys="role.menus"
                :data="menus"
                show-checkbox
                check-strictly
                node-key="id"
                :props="defaultProps"
              ></el-tree>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="saveRole()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSysRole, getSysMenus, saveSysRole, deleteSysRole } from '@/common/rbac.js';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      roles: [],
      dialogVisible: false,
      role: {
        name: '',
        code: '',
        menus: [],
      },
      menus: [],
      defaultCheckedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
    };
  },
  computed: {
    ...mapGetters(['check']),
  },
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      let resp = await getSysRole();
      console.log(resp);
      this.roles = resp.result;

      resp = await getSysMenus();
      this.menus = resp.result;
    },
    addRole() {
      this.role = {
        name: '',
        code: '',
        menus: [],
      };
      this.dialogVisible = true;
    },
    modifyRole(row) {
      this.role = row;
      this.dialogVisible = true;
      this.$forceUpdate();
    },
    async removeRole(row) {
      let param = { id: row.id };
      let resp = await deleteSysRole(param);
      console.log(resp);

      if (resp.code == 200) {
        this.$notify({
          title: '成功',
          message: '数据删除成功',
          type: 'success',
        });
        await this.load();
      }
    },
    async saveRole() {
      let menus = this.$refs.tree.getCheckedKeys();
      let param = this.role;
      if (!param.id) {
        param.id = 0;
      }
      param.menus = menus;

      let resp = await saveSysRole(param);
      if (resp.code == 200) {
        this.$notify({
          title: '成功',
          message: '数据保存成功',
          type: 'success',
        });
        this.dialogVisible = false;
        await this.load();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tree {
  height: 340px;
  overflow: auto;
}
</style>
